import React from 'react';
import { wrapper, item, current } from './label-generator-switch-occasion.module.scss'
import {IDirectories} from "../../models/directories.model";

interface ILabelGeneratorOccasion {
    currentOccasion: string;
    directories: IDirectories[]
    handleChangeOccasion: (arg0: string) => void;
}

const LabelGeneratorSwitchOccasion: React.FC<ILabelGeneratorOccasion> = ({currentOccasion, directories, handleChangeOccasion} ) => {

    return (
        <div className={wrapper}>
            {directories.map(occasion => {
                return (
                    occasion.name === currentOccasion
                        ? <div key={occasion.name} className={`${item} ${current}`}>{occasion.displayName}</div>
                        : <button key={occasion.name} className={item} onClick={
                            () => {handleChangeOccasion(occasion.name)}
                            }>
                                {occasion.displayName}
                        </button>
                )
            })}
        </div>
    )
}

export default LabelGeneratorSwitchOccasion;