import React, {useEffect, useMemo, useState} from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';
import { Form, useFormikContext } from 'formik';

import {
    buttonsWrapper,
    dateArea,
    formLayout,
    capacityArea,
    quantityArea,
    styleArea,
    firstLineArea,
    secondLineArea,
    styleAreaSlider,
    styleWrapper,
    styleWrapperSlider,
    fieldWrapper,
    swiped,
    cityArea,
    saveButton,
    labelButtons,
    labelButton,
    labelArrow,
    labelArrowPrev,
    wishesArea,
    yearsArea
} from './label-generator-form.module.scss';

import Arrow from '../../assets/images/svg/arrow-circle.svg';
import { ICreateLabelValues } from '../../models/create-form.model';
import { ICleanImage, ILabelStyleCreator } from '../../models/label-style.model';
import { IWishes } from '../../models/wishes.model';

import { formatStringToDate } from '../../utils/format-date';
import { useCampaignSettings } from '../../hooks/use-campaign-settings';

import FormField from '../atoms/form-field';
import FormRadio from '../atoms/form-radio';
import FormInput from '../atoms/form-input';
import Button from '../atoms/button';
import FormDatePicker from '../hoc/form-date-picker';
import FormSelect from '../atoms/form-select';

interface ILabelGeneratorForm {
    values: ICreateLabelValues;
    handleChange: (props: {
        values: ICreateLabelValues;
        savedLabels: ICreateLabelValues[];
        cleanImagesSource?: Record<string, ICleanImage> | undefined;
        setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    }) => void;
    handleLabelSave: () => void;
    handleLabelCancel: () => void;
    labelStyles: ILabelStyleCreator[];
    savedLabels: ICreateLabelValues[];
    wishes: IWishes[];
    occasion: string;
}

const LabelGeneratorForm: React.FC<ILabelGeneratorForm> = ({
    values,
    handleChange,
    handleLabelSave,
    handleLabelCancel,
    labelStyles,
    savedLabels,
    wishes,
    occasion,
}) => {
    const { t } = useI18next();

    const { submitForm, isValid, errors, touched, setFieldValue} =
        useFormikContext<ICreateLabelValues>();

    useEffect(() => {
        handleChange({ values, savedLabels, setFieldValue});
    }, [savedLabels, handleChange, values, setFieldValue]);

    const [gridLayoutColumns, setGridLayoutColumns] = useState<number>(5);

    const foundLabel = labelStyles.find((style) => style.type === values.style);

    const { minDate } = useCampaignSettings();

    const capacities = useMemo(() => {
        const options: string[] = [];

        if (foundLabel?.smallCapacities) {
            foundLabel?.smallCapacities.forEach((capacity) => {
                options.push(capacity.capacity);
            });
        }

        foundLabel?.capacities.forEach((capacity) => {
            options.push(capacity.capacity);
        });

        options.sort(compareByCapacityDesc);

        return options;
    }, [foundLabel?.capacities, foundLabel?.smallCapacities]);

    const styleImageSizes = useMemo(() => {
        const images: {
            [p: string]: {capacity: string, id: number} | undefined;
        } = {};
        labelStyles.forEach((style) => {
            const foundCapacity = style?.capacities.find(
                (capacity) => capacity.capacity === values.capacity
            );

            images[style.type] = foundCapacity;
        });
        return images;
    }, [labelStyles, values.capacity]);

    const handleFormSubmit = () => {
        submitForm().then(() => {
            if (isValid) {
                handleLabelSave();
            }
        });
    };

    const dateFormat = (datePicked: Date) => {
        return `${('0' + datePicked.getDate()).slice(-2)}.${(
            '0' +
            (datePicked.getMonth() + 1)
        ).slice(-2)}.${datePicked.getFullYear()} r.`;
    };

    const [isSwiped, setIsSwiped] = React.useState(false);

    const handleSwipe = () => {
        setIsSwiped(prevState => !prevState)
    }

    useEffect(() => {
        setGridLayoutColumns(Object.values(styleImageSizes).filter(style => style !== undefined).length)
    }, [styleImageSizes])

    return (
        <Form className={`${formLayout} ${occasion}-layout`}>
            <FormField
                className={`${styleArea} ${labelStyles.length > 5 ? styleAreaSlider : ''}`}
                labelText={t('label.creator.style.label')}
                id={'style'}
                hintText={errors.style && touched.style ? errors.style : null}
                errorStyle={!!(errors.style && touched.style)}
            >

                <div className={`grid-${gridLayoutColumns}-columns ${styleWrapper} ${gridLayoutColumns > 5 ? styleWrapperSlider : ''} ${isSwiped ? swiped : ''}`} role="group" aria-labelledby="styles options">
                    {labelStyles.map((style) => {

                        let imageData;

                        if (styleImageSizes[style.type]) {
                            if (styleImageSizes[style.type]?.capacity === '500 ml lemon' && style.lemonSampleLabelImage) {
                                imageData = getImage(style.lemonSampleLabelImage.localFile)
                            }
                            else {
                                imageData = getImage(style.sampleLabelImage.localFile)
                            }
                        }
                        else {
                            if (style.smallSampleLabelImage)
                            imageData = getImage(style.smallSampleLabelImage.localFile)
                        }

                        if (imageData) {
                            return (
                                <FormRadio
                                    key={`small_style_image_${style.labelStyleId}`}
                                    name={'style'}
                                    value={style.type}
                                    imageAlt={
                                        style[
                                            styleImageSizes[style.type]
                                                ? 'sampleLabelImage'
                                                : 'smallSampleLabelImage'
                                            ]?.alternativeText
                                    }
                                    verticalCenter={true}
                                    imageData={imageData}
                                    order={style.rowOrder}
                                    capacity={values.capacity}
                                    occasion={occasion}
                                />
                            )
                        }

                    })}
                </div>
                {(labelStyles.length > 5 && values.capacity === '500 ml') &&
                    <div className={labelButtons}>
                        <button className={labelButton} onClick={handleSwipe} disabled={!isSwiped}>
                            <Arrow className={`${labelArrow} ${labelArrowPrev}`} />
                        </button>
                        <button className={labelButton} onClick={handleSwipe} disabled={isSwiped}>
                            <Arrow className={labelArrow} />
                        </button>
                    </div>
                }
            </FormField>
            <FormField
                className={`${capacityArea} ${fieldWrapper}`}
                labelText={t('label.creator.capacity.label')}
                id={'capacity'}
                hintText={errors.capacity && touched.capacity ? errors.capacity : null}
                errorStyle={!!(errors.capacity && touched.capacity)}
            >
                <FormSelect
                    id={'capacity'}
                    name={'capacity'}
                    replaceValueField={'style'}
                    capacities={capacities}
                    styleImageSizes={styleImageSizes}
                    setIsSwiped={setIsSwiped}
                    occasion={occasion}
                />
            </FormField>
            <FormField
                className={`${quantityArea} ${fieldWrapper}`}
                labelText={t('label.creator.quantity.label')}
                id={'quantity'}
                hintText={
                    errors.quantity && touched.quantity
                        ? errors.quantity
                        : t('label.creator.quantity.hint')
                }
                tooltip={true}
                tooltipText={t('label.creator.description')}
                errorStyle={!!(errors.quantity && touched.quantity)}
            >
                <FormInput
                    type={'number'}
                    id={'quantity'}
                    name={'quantity'}
                    placeholder={t('label.creator.quantity.placeholder')}
                    errorStyle={!!(errors.quantity && touched.quantity)}
                />
            </FormField>
            <FormField
                className={`${firstLineArea} ${fieldWrapper}`}
                labelText={t('label.creator.first.line.label')}
                id={'firstLine'}
                hintText={
                    errors.firstLine && touched.firstLine
                        ? errors.firstLine
                        : t('label.creator.input.hint.first')
                }
                errorStyle={!!(errors.firstLine && touched.firstLine)}
            >
                <FormInput
                    id={'firstLine'}
                    name={'firstLine'}
                    placeholder={t('label.creator.input.placeholder')}
                    errorStyle={!!(errors.firstLine && touched.firstLine)}
                    maxlength={16}
                />
            </FormField>
            {(occasion === 'wedding' || occasion === 'anniversary') &&
                <FormField
                    className={`${secondLineArea} ${fieldWrapper}`}
                    labelText={t('label.creator.second.line.label')}
                    id={'secondLine'}
                    hintText={
                        errors.secondLine && touched.secondLine
                            ? errors.secondLine
                            : t('label.creator.input.hint.second')
                    }
                    errorStyle={!!(errors.secondLine && touched.secondLine)}
                >
                    <FormInput
                        id={'secondLine'}
                        name={'secondLine'}
                        placeholder={t('label.creator.input.placeholder')}
                        errorStyle={!!(errors.secondLine && touched.secondLine)}
                        maxlength={14}
                    />
                </FormField>
            }
            <FormField
                className={`${dateArea} ${fieldWrapper}`}
                labelText={t('label.creator.date.label')}
                id={'date'}
                hintText={errors.date && touched.date ? errors.date : null}
                errorStyle={!!(errors.date && touched.date)}
            >
                <FormDatePicker
                    id={'date'}
                    errorStyle={!!(errors.date && touched.date)}
                    format={'custom'}
                    customFormat={dateFormat}
                    initValue={formatStringToDate(values.date)}
                    minDate={minDate}
                />
            </FormField>
            {occasion === 'wedding' &&
                <FormField
                    className={`${cityArea} ${fieldWrapper}`}
                    id={'city'}
                    labelText={t('label.creator.city.label')}
                    hintText={
                        errors.city && touched.city ? errors.city : t('label.creator.city.hint')
                    }
                    errorStyle={!!(errors.city && touched.city)}
                >
                    <FormInput
                        id={'city'}
                        name={'city'}
                        placeholder={t('label.creator.city.placeholder')}
                        errorStyle={!!(errors.city && touched.city)}
                        maxlength={16}
                    />
                </FormField>
            }
            {(foundLabel?.smallCapacities?.find((capacity) => capacity.capacity === values.capacity) && values.style !== 'Glamour' && values.style !== 'Folk') &&
                <FormField
                    className={`${wishesArea} ${fieldWrapper}`}
                    labelText={t('label.creator.wishes.label')}
                    id={'wishes'}
                    hintText={errors.wishes && touched.wishes ? errors.wishes : ''}
                    errorStyle={!!(errors.wishes && touched.wishes)}
                >
                    <FormSelect
                        id={'wishes'}
                        name={'wishes'}
                        placeholder={t('label.creator.input.placeholder.wishes')}
                        wishes={wishes}
                        errorStyle={!!(errors.wishes && touched.wishes)}
                        setIsSwiped={setIsSwiped}
                    />
                </FormField>
            }

            {(occasion === 'anniversary' || occasion === 'birthday') &&
                <FormField
                    className={`${yearsArea} ${fieldWrapper}`}
                    id={'years'}
                    labelText={occasion === 'birthday' ? t('label.creator.years.birthday.label') : t('label.creator.years.anniversary.label')}
                    hintText={errors.years && touched.years ? errors.years : ''}
                    errorStyle={!!(errors.years && touched.years)}
                >
                    <FormInput
                        type={'number'}
                        id={'years'}
                        name={'years'}
                        placeholder={t('label.creator.years.placeholder')}
                        errorStyle={!!(errors.years && touched.years)}
                    />
                </FormField>
            }

            <div className={buttonsWrapper}>
                {savedLabels.length > 0 && (
                    <Button
                        fullWidth={true}
                        variant={'text'}
                        type={'button'}
                        onClick={handleLabelCancel}
                    >
                        {t('label.creator.cancel.label')}
                    </Button>
                )}
                <Button
                    className={saveButton}
                    fullWidth={true}
                    type={'button'}
                    onClick={handleFormSubmit}
                    id={'label-creator-submit'}
                >
                    {t('label.creator.save.label')}
                </Button>
            </div>
        </Form>
    );
};

function compareByCapacityDesc(a: string, b: string) {
    if (a < b) {
        return 1;
    }
    if (a > b) {
        return -1;
    }
    return 0;
}

export default LabelGeneratorForm;
