import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Field } from 'formik';

import {
    radio,
    radioVerticalCenter,
    input,
    label,
    checkmark,
    image,
    imageRatio,
} from './form-radio.module.scss';
import RatioImage from './ratio-image';

interface IFormRadio {
    className?: string;
    type?: string;
    name: string;
    value: string;
    imageData?: IGatsbyImageData;
    imageAlt?: string;
    verticalCenter?: boolean;
    order?: number;
    capacity?: string;
    occasion: string;
}

const FormRadio: React.FC<IFormRadio> = ({
    type = 'radio',
    name,
    value,
    imageData,
    imageAlt = '',
    className = '',
    verticalCenter = false,
    order,
    capacity,
    occasion
}) => {
    return (
        <label className={`${radio} ${className} ${verticalCenter ? radioVerticalCenter : ''} ${capacity === '500 ml lemon' && occasion === 'birthday' ? `radio-row-${order}` : ''}`}>
            <Field type={type} name={name} className={input} value={value} />
            <span className={checkmark} />
            <span className={label}>{value}</span>
            {imageData && (
                <RatioImage
                    className={image}
                    ratioClass={imageRatio}
                    image={imageData}
                    alt={imageAlt}
                    objectFit={'contain'}
                    draggable={false}
                    id={`radio-image-${value}`}
                />
            )}
        </label>
    );
};

export default FormRadio;
