import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {navigate} from "gatsby";

import {
    modal,
    popup,
    wrapper,
    header,
    contentArea,
    buttonsRow,
    button,
} from './change-style-modal.module.scss';

import { useFormikContext } from 'formik';

import { ICreateLabelValues } from '../../models/create-form.model';

import Modal from '../hoc/modal';
import Button from '../atoms/button';
import {ICleanImage, ILabelStyleCreator, ILabelTexts} from '../../models/label-style.model';
import {IDirectories} from "../../models/directories.model";

interface IChangeStyleModal {
    className?: string;
    closeModal: () => void;
    savedLabels: ICreateLabelValues[];
    saveSavedLabels: (newLabels: ICreateLabelValues[]) => void;
    cleanImages: Record<string, ICleanImage> | undefined;
    canvasRef: HTMLCanvasElement;
    labelStyles: ILabelStyleCreator[];
    reDrawLabel: (
        savedLabels: ICreateLabelValues,
        cleanImages: Record<string, ICleanImage> | undefined,
        canvasRef: React.RefObject<HTMLCanvasElement>,
        occasion: string,
        labelStyles: ILabelStyleCreator[],
        newStyle: string,
        labelTexts: ILabelTexts,
        imageDigits: HTMLImageElement[] | undefined,
        isImageLoaded: boolean
    ) => string | undefined;
    occasion: string;
    setNextOccasion: (occasion: string) => void;
    directories: IDirectories[]
    nextOccasion: string;
    labelTexts: ILabelTexts;
    imageDigits: HTMLImageElement[] | undefined;
    isImageLoaded: boolean;
}

const ChangeStyleModal: React.FC<IChangeStyleModal> = ({
    className = '',
    closeModal,
    savedLabels,
    saveSavedLabels,
    labelStyles,
    canvasRef,
    cleanImages,
    reDrawLabel,
    occasion,
    directories,
    nextOccasion,
    setNextOccasion,
    labelTexts,
    imageDigits,
    isImageLoaded
}) => {
    const { t } = useI18next();
    const { values, setFieldValue } = useFormikContext<ICreateLabelValues>();
    const newCanvasRef = useRef<HTMLCanvasElement>(canvasRef);

    const handleSubmit = () => {
        const newSavedLabels: ICreateLabelValues[] = [];
        savedLabels.forEach((label) => {
            newSavedLabels.push({
                ...label,
                style: values.style,
                image: reDrawLabel(label,cleanImages,newCanvasRef,occasion,labelStyles,values.style,labelTexts,imageDigits, isImageLoaded)
            });
        });

        saveSavedLabels(newSavedLabels);
        closeModal();
    };

    const handleCancel = () => {
        setFieldValue('style', savedLabels[0].style);
        setNextOccasion(occasion);
        closeModal();
    };

    const handleChangeOccasion = () => {
        const newSavedLabels: ICreateLabelValues[] = [];
        const directory = directories.filter(directory => directory.name === nextOccasion);

        savedLabels.forEach(label => {
            if (label.occasion === nextOccasion) {
                newSavedLabels.push(label)
            }
        })

        saveSavedLabels(newSavedLabels);
        closeModal();

        navigate(directory[0].link)
    }

    return (
        <Modal className={modal}>
            <div className={`${popup} ${className}`}>
                <div className={wrapper}>
                    {
                        occasion === nextOccasion  &&
                        <>
                            <span className={header}>{t('change.style.modal.header')}</span>
                            <div className={contentArea}>{t('change.style.modal.text')}</div>
                            <div className={buttonsRow}>
                                <Button onClick={handleCancel} className={button} variant={'text'}>
                                    {t('change.style.modal.cancel')}
                                </Button>
                                <Button onClick={handleSubmit} className={button}>
                                    {t('change.style.modal.submit')}
                                </Button>
                            </div>
                        </>
                    }
                    {
                        occasion !== nextOccasion &&
                        <>
                            <span className={header}>{t('change.occasion.modal.header')}</span>
                            <div className={contentArea}>{t('change.occasion.modal.text')}</div>
                            <div className={buttonsRow}>
                                <Button onClick={handleCancel} className={button} variant={'text'}>
                                    {t('change.occasion.modal.cancel')}
                                </Button>
                                <Button onClick={handleChangeOccasion} className={button}>
                                    {t('change.occasion.modal.submit')}
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default ChangeStyleModal;
