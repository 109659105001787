import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';
import { Formik } from 'formik';

import { wrapper, canvasLayout, canvas } from './label-generator.module.scss';

import { ICleanImage, ILabelStyleCreator } from '../../models/label-style.model';
import { ICreateLabelValues } from '../../models/create-form.model';
import { IWishes } from '../../models/wishes.model';
import { ILabelTexts } from "../../models/label-style.model";

import { getLabelFormSchema } from '../../formik/label-form.config';

import FormLabel from '../atoms/form-label';
import LabelGeneratorForm from '../molecules/label-generator-form';
import ChangeStyleModal from './change-style-modal';
import LabelGeneratorSwitchOccasion from "./label-generator-switch-occasion";
import {navigate} from "gatsby";
import {IDirectories} from "../../models/directories.model";

interface ILabelGenerator {
    savedLabels: ICreateLabelValues[];
    initialValues: ICreateLabelValues;
    onAddNewSavedLabel: (values: ICreateLabelValues, labelImage: string | undefined) => void;
    onHandleAddSavedLabel: () => void;
    onHandleCancelLabelForm: () => void;
    labelStyles: ILabelStyleCreator[];
    saveSavedLabels: (newLabels: ICreateLabelValues[]) => void;
    wishes: IWishes[];
    occasion: string;
    directories: IDirectories[];
}

const LabelGenerator: React.FC<ILabelGenerator> = ({
    savedLabels,
    initialValues,
    onAddNewSavedLabel,
    onHandleAddSavedLabel,
    onHandleCancelLabelForm,
    labelStyles,
    saveSavedLabels,
    wishes,
    occasion,
    directories
}) => {
    const [isLemon, setIsLemon] = useState(false);
    const [cleanImages, setCleanImages] = useState<Record<string, ICleanImage>>();
    const { t } = useI18next();

    const labelFormSchema = useMemo(() => getLabelFormSchema(t), [t, occasion]);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [modalIsActive, setModalIsActive] = useState(false);

    const [nextOccasion, setNextOccasion] = useState(occasion);
    const [imageDigits, setImageDigits] = useState<HTMLImageElement[] | undefined>();
    const [isCleanLabelsLoaded, setIsCleanLabelsLoaded] = useState(false);

    const labelTexts: ILabelTexts = {
        years: {
            displayName: `${t('label.text.years')}`,
            displayAltName: `${t('label.text.years.alternative')}`,
        },
        together: {
            displayName: `${t('label.text.together')}`
        }
    }

    function closeModal() {
        setModalIsActive(false);
    }

    function handleChangeOccasion(occasionName: string) {

        setNextOccasion(occasionName)
        let deleteLabels = false;

        if (savedLabels) {
            savedLabels.forEach(label => {
                if (label.occasion !== occasionName) {
                    setModalIsActive(true)
                    deleteLabels = true;
                    return false;
                }
            })
        }

        if (!deleteLabels) {
            const directory = directories.filter(directory => directory.name === occasionName);
            navigate(directory[0].link);
        }
    }

    function getLabelsCount() {

        let count = occasion === 'wedding' ? labelStyles.length * 2 : labelStyles.length;

        labelStyles.forEach(label => {

            if (label.lemonCleanLabelImage) {
                count++;
            }
        })

        return count;
    }

    const numberOfLabelImages = getLabelsCount();

    let counter = 0;

    function incrementCounter(images: { [index: string]: ICleanImage }) {
        counter++;
        if (counter === numberOfLabelImages) {
            handleChange({ values: initialValues, cleanImagesSource: images});
            setIsCleanLabelsLoaded(true);
        }
    }

    useEffect(() => {
        const images: {
            [index: string]: ICleanImage;
        } = {};

        labelStyles.forEach((style) => {
            const image = new Image();
            const imageSrc = getImage(style.cleanLabelImage.localFile)?.images?.fallback?.src;

            let smallImage, smallImageSrc, lemonImage, lemonImageSrc;

            if (style.smallCleanLabelImage) {
                smallImage = new Image();
                smallImageSrc = getImage(style.smallCleanLabelImage.localFile)?.images?.fallback?.src;
            }

            if (style.lemonCleanLabelImage) {
                lemonImage = new Image();
                lemonImageSrc = getImage(style.lemonCleanLabelImage.localFile)?.images?.fallback?.src;
            }

            if (style.imageDigits?.length) {

            const digits = style.imageDigits.map(digit => {
                    const img = new Image();
                    let digitSrc = style.imageDigits && getImage(style.imageDigits[style.imageDigits?.indexOf(digit)].localFile.childrenImageSharp[0])?.images?.fallback?.src
                    img.src = digitSrc ? digitSrc : '';
                    return img;

            })
                setImageDigits(digits);
            }

            if (imageSrc) {
                image.src = imageSrc;
                image.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    image: image,
                };
            }

            if (smallImage && smallImageSrc) {
                smallImage.src = smallImageSrc;
                smallImage.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    smallImage: smallImage,
                };
            }

            if (lemonImage && lemonImageSrc) {
                lemonImage.src = lemonImageSrc;
                lemonImage.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    lemonImage: lemonImage,
                };
            }
        });

        setCleanImages(images);
    }, []);

    function handleChange(props: {
        values: ICreateLabelValues;
        savedLabels?: ICreateLabelValues[];
        cleanImagesSource?: Record<string, ICleanImage> | undefined;
        setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    }) {
        const { values, savedLabels, cleanImagesSource = cleanImages, setFieldValue} = props;
        const foundLabel = labelStyles.find((style) => style.type === values.style);
        const foundCapacity = foundLabel?.capacities.find(
            (capacity) => capacity.capacity === values.capacity
        );
        const isLemonCapacity = foundCapacity?.capacity === '500 ml lemon';

        if (savedLabels && savedLabels.length && values.style !== savedLabels[0].style && occasion === 'wedding') {
            setModalIsActive(true);
            return false;
        }

        if (!values.secondLine.startsWith('& ') && setFieldValue && (values.occasion === 'wedding' || values.occasion === 'anniversary' || values.capacity === 'small')) {
            let text = values.secondLine.substring(0, 3);
            text = text.replace('&', '');
            text = text.replace(' ', '');
            setFieldValue('secondLine', `& ${text}${values.secondLine.substring(3)}`);
        }

        setIsLemon(isLemonCapacity);

        if (foundLabel) {
            drawLabel({
                cleanImages: cleanImagesSource,
                values: values,
                occasion: occasion,
                canvasRef: canvasRef,
                savedLabel: foundLabel,
                isSmall: !foundCapacity,
                isLemon: isLemon,
                labelTexts: labelTexts,
                imageDigits: imageDigits,
                isImageLoaded: isCleanLabelsLoaded
            });
        }

        return false;
    }

    return (
        <div className={wrapper}>
            <div className={canvasLayout}>
                <FormLabel labelText={t('label.creator.image.label')} />
                <canvas ref={canvasRef} className={canvas} width="390" height="655" />
            </div>
            <div>
                <Formik
                    enableReinitialize={true}
                    validationSchema={labelFormSchema}
                    initialValues={initialValues}
                    validateOnMount={true}
                    onSubmit={(values, actions) => {
                        onAddNewSavedLabel(values, getDrawnLabel(canvasRef));
                        actions.setSubmitting(false);
                        actions.resetForm({
                            values: initialValues,
                        });
                    }}
                >
                    {({ values }) => {
                        return (
                            <>
                                {modalIsActive && canvasRef.current && (
                                    <ChangeStyleModal
                                        closeModal={closeModal}
                                        savedLabels={savedLabels}
                                        saveSavedLabels={saveSavedLabels}
                                        cleanImages={cleanImages}
                                        canvasRef={canvasRef.current}
                                        labelStyles={labelStyles}
                                        reDrawLabel={reDrawLabel}
                                        occasion={occasion}
                                        directories={directories}
                                        nextOccasion={nextOccasion}
                                        setNextOccasion={setNextOccasion}
                                        labelTexts={labelTexts}
                                        imageDigits={imageDigits}
                                        isImageLoaded={isCleanLabelsLoaded}
                                    />
                                )}
                                <LabelGeneratorSwitchOccasion handleChangeOccasion={handleChangeOccasion} currentOccasion={occasion} directories={directories}/>
                                <LabelGeneratorForm
                                    values={values}
                                    handleChange={handleChange}
                                    handleLabelSave={onHandleAddSavedLabel}
                                    handleLabelCancel={onHandleCancelLabelForm}
                                    labelStyles={labelStyles}
                                    savedLabels={savedLabels}
                                    wishes={wishes}
                                    occasion={occasion}
                                />
                            </>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

function reDrawLabel(
    savedLabel: ICreateLabelValues,
    cleanImages: Record<string, ICleanImage> | undefined,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    occasion: string,
    labelStyles: ILabelStyleCreator[],
    newStyle: string,
    labelTexts: ILabelTexts,
    imageDigits: HTMLImageElement[] | undefined,
    isImageLoaded: boolean
) {
    const foundLabel = labelStyles.find((style) => style.type === newStyle);
    const foundCapacity = foundLabel?.capacities.find(
        (capacity) => capacity.capacity === savedLabel.capacity
    );
    const isLemon = foundCapacity?.capacity === '500 ml lemon';

    let labelImage: string | undefined = '';

    if (foundLabel) {
        const drawLabelProps = {
            cleanImages: cleanImages,
            values: {
                ...savedLabel,
                style: newStyle,
            },
            occasion: occasion,
            canvasRef: canvasRef,
            savedLabel: foundLabel,
            isSmall: !foundCapacity,
            isLemon: isLemon,
            labelTexts: labelTexts,
            imageDigits: imageDigits,
            isImageLoaded: isImageLoaded
        };

        drawLabel(drawLabelProps);
        labelImage = getDrawnLabel(canvasRef);
    }
    return labelImage;
}

function getDrawnLabel(canvasRef: React.RefObject<HTMLCanvasElement>) {
    const canvas = canvasRef.current;

    if (canvas === null) return;
    return canvas.toDataURL();
}

function drawLabel(props: {
    cleanImages: { [p: string]: ICleanImage } | undefined;
    values: ICreateLabelValues;
    occasion: string,
    canvasRef: React.RefObject<HTMLCanvasElement>;
    savedLabel: ILabelStyleCreator;
    isSmall: boolean;
    isLemon: boolean;
    labelTexts: ILabelTexts;
    imageDigits: HTMLImageElement[] | undefined;
    isImageLoaded: boolean;
}) {
    const { cleanImages, values, occasion, canvasRef, savedLabel, isSmall, isLemon, labelTexts, imageDigits, isImageLoaded } = props;
    const canvas = canvasRef.current;

    if (!cleanImages) return false;

    if (canvas === null) return;
    const context = canvas.getContext('2d');

    if (context === null) return;

    const textStyle = getTextStyle(isSmall, isLemon, savedLabel);
    const image = getCleanImage(isSmall, isLemon, cleanImages[values.style]);

    if (!image) return false;

    drawEmptyLabel({
        context: context,
        canvasSource: image,
        occasion: occasion,
        canvas: canvas,
        capacity: values.capacity,
        isImageLoaded: isImageLoaded,
        callback: () => {
            drawTextOverLabel({
                context: context,
                canvas: canvas,
                occasion: occasion,
                labelTexts: labelTexts,
                style: values.style,
                capacity: values.capacity,
                firstLine: values.firstLine,
                secondLine: values.secondLine,
                dateLine: values.date,
                cityLine: values.city,
                yearsLine: values.years,
                wishesLine: values.wishes,
                imageDigits: imageDigits,
                textStyle: textStyle,
            });
        },
    });
}

function drawEmptyLabel(props: {
    context: CanvasRenderingContext2D;
    canvasSource: HTMLImageElement;
    canvas: HTMLCanvasElement;
    occasion: string;
    capacity: string;
    isImageLoaded: boolean;
    callback: () => void;
}) {
    const { context, canvasSource, canvas, capacity, isImageLoaded, callback } = props;
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    if (isImageLoaded && canvasSource.height > 0) {
        capacity === '100 ml' ?
            context.drawImage(
                canvasSource,
                canvas.width / 2 - clearSmallLabelSize.width / 2,
                canvas.height / 2 - clearSmallLabelSize.height / 2,
                380,
                615
            )
            :
            context.drawImage(
                canvasSource,
                canvas.width / 2 - clearLabelSize.width / 2,
                canvas.height / 2 - clearLabelSize.height / 2,
                305,
                615
            );

        callback();
    }
}

function drawTextOverLabel(props: {
    context: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;
    labelTexts: ILabelTexts;
    imageDigits: HTMLImageElement[] | undefined;
    occasion: string;
    style: string;
    capacity: string;
    firstLine: string;
    secondLine?: string | undefined;
    dateLine: string;
    cityLine?: string | undefined;
    yearsLine?: number | undefined;
    yearsTextLine?: string | undefined;
    wishesLine?: string | undefined;
    textStyle: {
        fontFamily: string;
        fontSize: number | undefined;
        fontColor: string | undefined;
        secondFontColor?: string | undefined;
        additionalFontColor?: string | undefined;
        firstLineOffsetY: number | undefined;
        secondLineOffsetY?: number | undefined;
        dateFontSize: number | undefined;
        dateLineOffsetY: number | undefined;
        cityLineOffsetY?: number | undefined;
        yearsFontSize?: number | undefined;
        yearsAdditionalFontSize?: number | undefined;
        yearsLineOffsetY?: number | undefined;
        yearsOffsetX?: number | undefined;
        yearsTextOffsetX?: number | undefined;
        yearsTextFontSize?: number | undefined;
        wishesLineOffsetY?: number | undefined;
        fontWeight: number;
    };
}) {
    const { context, canvas, occasion, style, capacity, labelTexts, firstLine, secondLine, cityLine, dateLine, yearsLine, wishesLine, imageDigits, textStyle} = props;

    if (textStyle.fontColor) {
        context.fillStyle = textStyle.fontColor;
    }

    const isLemon = capacity === '500 ml lemon';

    if (style === "Podróżniczy" && isLemon) {
        textStyle.fontFamily = 'Beachworks'
    }

    context.textAlign = 'center';
    context.font = textStyle.fontWeight + ' ' + textStyle.fontSize + 'px ' + textStyle.fontFamily;

    if (style === 'Imprezowy' && isLemon) {
        context.font = 600 + ' ' + textStyle.fontSize + 'px ' + 'Avenir';
    }

    if (style === 'Komiksowy') {
        context.shadowColor="black";
        context.shadowOffsetY=2;
        context.shadowOffsetX=0.5;
    }

    if (style === 'Odważny') {
        context.shadowColor="black";
        context.shadowOffsetY=1;
    }

    if (style === 'Muzyczny' && !isLemon) {
        context.shadowColor="black";
        context.shadowOffsetY=3;
        context.shadowOffsetX=0.5;
    }

    if (style === 'Imprezowy' && !isLemon) {
        context.shadowColor="black";
        context.shadowOffsetY=1;

        const gradient = context.createLinearGradient(200, 230, 290, 310);
        gradient.addColorStop(0, `${textStyle.fontColor}`);
        gradient.addColorStop(0.5, `${textStyle.additionalFontColor}`);
        gradient.addColorStop(1, `${textStyle.fontColor}`);
        context.fillStyle = gradient;
    }

    if (textStyle.firstLineOffsetY) {

        if (style === 'Muzyczny' && isLemon) {
            context.fillText(firstLine, canvas.width / 2 - 55 , canvas.height * textStyle.firstLineOffsetY);
        }
        else {
            context.fillText(firstLine, canvas.width / 2, canvas.height * textStyle.firstLineOffsetY);
        }
    }

    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowColor= 'white';

    if (textStyle.secondLineOffsetY && secondLine) {
        context.fillText(secondLine, canvas.width / 2, canvas.height * textStyle.secondLineOffsetY);
    }

    context.font = textStyle.fontWeight + ' ' + textStyle.dateFontSize + 'px ' + (style === 'Vintage' ? 'Lobster' : textStyle.fontFamily);

    if (style === 'Odważny' && textStyle.additionalFontColor) {
        context.fillStyle = textStyle.additionalFontColor;
    }

    if (textStyle.cityLineOffsetY && cityLine) {

        if (textStyle.additionalFontColor && (style === 'Rustykalny' && capacity !== '100 ml')) {
            context.fillStyle = textStyle.additionalFontColor;
        }

        context.fillText(cityLine, canvas.width / 2, canvas.height * textStyle.cityLineOffsetY);

        if (textStyle.fontColor) {
            context.fillStyle = textStyle.fontColor;
        }
    }

    if (textStyle.wishesLineOffsetY && wishesLine) {
        context.fillText(wishesLine, canvas.width / 2, canvas.height * textStyle.wishesLineOffsetY);
    }

    if (textStyle.dateLineOffsetY) {

        if (textStyle.additionalFontColor && (style === 'Rustykalny' && capacity === '100 ml')) {
            context.fillStyle = textStyle.additionalFontColor;
        }

        if (style === 'Vintage' && textStyle.yearsOffsetX) {
            context.fillText(dateLine.replace('r.',''), canvas.width / 2 + (textStyle.yearsOffsetX + 25), canvas.height * textStyle.dateLineOffsetY);
        }

        else if (style === 'Romantyczny' && isLemon) {
            context.fillText(dateLine, canvas.width / 2 - 50, canvas.height * textStyle.dateLineOffsetY);
        }

        else if (occasion === 'anniversary' && style === 'Glamour' && isLemon) {
            context.fillText(dateLine.replace('r.',''), canvas.width / 2 - 92, canvas.height * textStyle.dateLineOffsetY);
        }

        else if (style === 'Muzyczny') {

            if (isLemon) {
                context.fillText(dateLine, canvas.width / 2  - 55, canvas.height * textStyle.dateLineOffsetY);
            }
            else {
                context.fillStyle = '#000000';
                context.fillText(dateLine, canvas.width / 2  - 50, canvas.height * textStyle.dateLineOffsetY);
            }

        }

        else if (style === 'Podróżniczy') {

            if (isLemon) {
                context.fillText(dateLine, canvas.width / 2 - 10, canvas.height * textStyle.dateLineOffsetY);
            }
            else {
                context.save();
                context.rotate(-(8 * Math.PI) / 180)
                context.fillText(dateLine, canvas.width / 2 - 125, canvas.height * textStyle.dateLineOffsetY);
                context.restore();
            }
        }

        else if (style === "Imprezowy" && !isLemon) {
            const gradient = context.createLinearGradient(200, 250, 280, 290);
            gradient.addColorStop(0, `${textStyle.fontColor}`);
            gradient.addColorStop(0.5, `${textStyle.additionalFontColor}`);
            gradient.addColorStop(1, `${textStyle.fontColor}`);
            context.fillStyle = gradient;
            context.fillText(dateLine, canvas.width / 2, canvas.height * textStyle.dateLineOffsetY);
        }

        else {
            context.fillText(dateLine, canvas.width / 2, canvas.height * textStyle.dateLineOffsetY);
        }

        if (textStyle.fontColor) {
            context.fillStyle = textStyle.fontColor;
        }
    }

    if (style === 'Komiksowy') {
        context.shadowOffsetX = 1;
        context.shadowOffsetY = 4;
        context.shadowColor = "black";
    }

    if (textStyle.additionalFontColor) {

        if (style === 'Odważny') {
            context.fillStyle = textStyle.additionalFontColor;
            context.shadowOffsetY=2;
            context.shadowColor= "black";
        }

        if (style === 'Muzyczny' && !isLemon) {
            context.shadowOffsetX = 1;
            context.shadowOffsetY = 4;
            context.shadowColor = "black";
            context.fillStyle = textStyle.additionalFontColor;
        }

        if (style === 'Podróżniczy' && isLemon) {
            context.fillStyle = textStyle.additionalFontColor;
        }
    }

    context.font = textStyle.fontWeight + ' ' + textStyle.yearsFontSize + 'px ' + (style === 'Vintage' ? 'Lobster' : textStyle.fontFamily);

    if (textStyle.yearsLineOffsetY && yearsLine) {

        const digits = yearsLine.toString().split('');
        let lastDigit = digits[digits.length - 1];
        let firstWord = labelTexts.years.displayName
        let secondWord = labelTexts.together.displayName;

        if ((lastDigit === '2' || lastDigit === '3' || lastDigit === '4') && (yearsLine.toString() !== '12' && yearsLine.toString() !== '13' && yearsLine.toString() !== '14')) {
            firstWord = labelTexts.years.displayAltName;
        }

        if (style === 'Glamour' && textStyle.fontColor) {

            if (isLemon) {
                context.fillStyle = textStyle.additionalFontColor ? textStyle.additionalFontColor : textStyle.fontColor;
            }
            else {
                context.fillStyle = textStyle.secondFontColor ? textStyle.secondFontColor : textStyle.fontColor;
            }

            context.fillText(yearsLine.toString() + firstWord, canvas.width / 2, canvas.height * textStyle.yearsLineOffsetY);
        }

        else if (style === 'Romantyczny' && isLemon && textStyle.additionalFontColor && textStyle.fontColor) {
            context.fillStyle = textStyle.additionalFontColor;
            context.shadowOffsetY = 1;
            context.shadowColor = textStyle.fontColor;
            context.fillText(yearsLine.toString(), canvas.width / 2, canvas.height * textStyle.yearsLineOffsetY);
            context.fillStyle = textStyle.fontColor;
        }

        else if (style === 'Vintage' && textStyle.yearsOffsetX) {

            if (isLemon && textStyle.additionalFontColor) {
                context.fillStyle = textStyle.additionalFontColor;
            }

            context.save();
            context.rotate(-(4 * Math.PI) / 180)
            context.fillText(yearsLine.toString() + '!', canvas.width / 2 + textStyle.yearsOffsetX, canvas.height * textStyle.yearsLineOffsetY);
            context.restore();
        }

        else if (occasion === 'birthday') {

            if (style === 'Muzyczny' && isLemon) {
                context.fillText(yearsLine.toString(), canvas.width / 2 + (textStyle.yearsOffsetX ? textStyle.yearsOffsetX : 0), canvas.height * textStyle.yearsLineOffsetY);

                context.font = textStyle.fontWeight + ' ' + 40 + 'px ' + textStyle.fontFamily;
                context.fillText(firstWord, canvas.width / 2 + (textStyle.yearsTextOffsetX ? textStyle.yearsTextOffsetX : 0), canvas.height * textStyle.yearsLineOffsetY);
            }
            else if (style === 'Imprezowy' && imageDigits) {

                if (isLemon) {

                    digits.forEach((digit, index) => {

                        if (index === 0) {
                            context.drawImage(imageDigits[parseFloat(digit)], canvas.width / 2 - 70, canvas.height * (textStyle.yearsLineOffsetY ? textStyle.yearsLineOffsetY : 1), 50,70)
                        }
                        if (index === 1) {
                            context.drawImage(imageDigits[parseFloat(digit)], canvas.width / 2 - 20, canvas.height * (textStyle.yearsLineOffsetY ? textStyle.yearsLineOffsetY : 1), 50,70)
                        }
                        return false;
                    })

                    context.font = textStyle.fontWeight + ' ' + textStyle.yearsAdditionalFontSize + 'px ' + 'AvenirCondensed';
                    const gradient = context.createLinearGradient( 275,260,215,270);
                    gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
                    gradient.addColorStop(0.5, `#E4C56C`);
                    gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
                    context.fillStyle = gradient;
                    context.fillText(firstWord.toUpperCase(), canvas.width / 2 + 55, canvas.height * (textStyle.yearsLineOffsetY + 0.1));
                }
                else {

                    digits.forEach((digit, index) => {

                        if (index === 0) {
                            context.drawImage(imageDigits[parseFloat(digit)], canvas.width / 2 - 75, canvas.height * (textStyle.yearsLineOffsetY ? textStyle.yearsLineOffsetY : 1), 70,90)
                        }
                        if (index === 1) {
                            context.drawImage(imageDigits[parseFloat(digit)], canvas.width / 2 - 3, canvas.height * (textStyle.yearsLineOffsetY ? textStyle.yearsLineOffsetY : 1), 70,90)
                        }
                        return false;
                    })

                    context.drawImage(imageDigits[10], canvas.width / 2 + 68, canvas.height * textStyle.yearsLineOffsetY, 30,90)
                }

            }

            else {
                context.fillText(yearsLine.toString() + '!', canvas.width / 2 + (textStyle.yearsOffsetX ? textStyle.yearsOffsetX : 0), canvas.height * textStyle.yearsLineOffsetY);
            }

        }

        else {
            context.fillText(yearsLine.toString(), canvas.width / 2, canvas.height * textStyle.yearsLineOffsetY);
        }

        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        context.shadowColor = "white";
        context.font = textStyle.fontWeight + ' ' + textStyle.yearsTextFontSize + 'px ' + textStyle.fontFamily;

        if (occasion === 'anniversary') {

            if (style === 'Romantyczny' && textStyle.yearsTextOffsetX) {
                context.fillText(firstWord + ' ' + secondWord, canvas.width / 2 + textStyle.yearsTextOffsetX, canvas.height * (textStyle.yearsLineOffsetY + 0.025));
            }

            else if (style === 'Glamour') {

                if (isLemon && textStyle.yearsTextOffsetX) {
                    context.fillText(secondWord, canvas.width / 2 + textStyle.yearsTextOffsetX, canvas.height * textStyle.yearsLineOffsetY);
                }
                else {
                    context.fillText(secondWord, canvas.width / 2, canvas.height * (textStyle.yearsLineOffsetY + 0.050));
                }
            }

            else {

                if (textStyle.yearsTextOffsetX) {
                    context.save();
                    context.rotate((2 * Math.PI) / 180)

                    context.font = textStyle.fontWeight + ' ' + textStyle.yearsFontSize + 'px ' + 'Lobster';
                    context.fillText(firstWord, canvas.width / 2 + textStyle.yearsTextOffsetX, canvas.height * (textStyle.yearsLineOffsetY - 0.03));

                    context.restore()

                    if (isLemon && textStyle.fontColor) {
                        context.fillStyle = textStyle.fontColor;
                    }

                    context.font = textStyle.fontWeight + ' ' + textStyle.yearsTextFontSize + 'px ' + 'Lobster';
                    context.fillText(secondWord, canvas.width / 2 + textStyle.yearsTextOffsetX - 15, canvas.height * (textStyle.yearsLineOffsetY + 0.02));
                }
            }
        }
    }
    context.shadowColor = "white";
}

function getTextStyle(isSmall: boolean, isLemon: boolean, savedLabel: ILabelStyleCreator) {
    if (isSmall)
        return {
            fontFamily: savedLabel.fontFamily,
            fontSize: savedLabel.smallFontSize,
            fontColor: savedLabel.smallFontColor,
            additionalFontColor: savedLabel.additionalFontColor,
            firstLineOffsetY: savedLabel.smallFirstLineOffsetY,
            secondLineOffsetY: savedLabel.smallSecondLineOffsetY,
            dateFontSize: savedLabel.smallDateFontSize,
            dateLineOffsetY: savedLabel.smallDateLineOffsetY,
            wishesLineOffsetY: savedLabel.wishesLineOffsetY,
            fontWeight: 400,
        };

    else if(isLemon)
        return {
            fontFamily: savedLabel.fontFamily,
            fontSize: savedLabel.lemonFontSize,
            fontColor: savedLabel.lemonFontColor,
            additionalFontColor: savedLabel.additionalFontColor,
            firstLineOffsetY: savedLabel.lemonFirstLineOffsetY,
            secondLineOffsetY: savedLabel.lemonSecondLineOffsetY,
            dateFontSize: savedLabel.lemonDateFontSize,
            dateLineOffsetY: savedLabel.lemonDateLineOffsetY,
            yearsFontSize: savedLabel.lemonYearsFontSize,
            yearsAdditionalFontSize: savedLabel.lemonYearsAdditionalFontSize,
            yearsLineOffsetY: savedLabel.lemonYearsLineOffsetY,
            yearsOffsetX: savedLabel.lemonYearsOffsetX,
            yearsTextOffsetX: savedLabel.lemonYearsTextOffsetX,
            yearsTextFontSize: savedLabel.lemonYearsTextFontSize,
            cityLineOffsetY: savedLabel.lemonCityLineOffsetY,
            fontWeight: 400
        };

    return {
        fontFamily: savedLabel.fontFamily,
        fontSize: savedLabel.fontSize,
        fontColor: savedLabel.fontColor,
        secondFontColor: savedLabel.smallFontColor,
        additionalFontColor: savedLabel.additionalFontColor,
        firstLineOffsetY: savedLabel.firstLineOffsetY,
        secondLineOffsetY: savedLabel.secondLineOffsetY,
        dateFontSize: savedLabel.dateFontSize,
        dateLineOffsetY: savedLabel.dateLineOffsetY,
        yearsFontSize: savedLabel.yearsFontSize,
        yearsLineOffsetY: savedLabel.yearsLineOffsetY,
        yearsOffsetX: savedLabel.yearsOffsetX,
        yearsTextOffsetX: savedLabel.yearsTextOffsetX,
        yearsTextFontSize: savedLabel.yearsTextFontSize,
        cityLineOffsetY: savedLabel.cityLineOffsetY,
        fontWeight: 400,
    };
}

function getCleanImage(isSmall: boolean, isLemon: boolean, images: ICleanImage) {
    if (isSmall) return images.smallImage;
    else if (isLemon) return images.lemonImage;
    return images.image;
}

const clearLabelSize = {
    width: 305,
    height: 615,
};

const clearSmallLabelSize = {
    width: 380,
    height: 615,
};

export default LabelGenerator;
