import React, { useEffect, useMemo, useState } from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import {
    layout,
    container,
    title,
    description,
    formWithBorder,
    buttonsWrapper,
    preloadFonts,
    abrilFatFace,
    alexBrush,
    bangers,
    caveatRegular,
    greatVibes,
    libreBodoni,
    libreBodoniItalic,
    lobster,
    marckScript,
    msMadiRegular,
    whisper,
    beachworks,
    avenir,
    avenirCondensed,
} from './label-generator-page.module.scss';

import { ILabelStyleCreator } from '../models/label-style.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IWishes } from '../models/wishes.model';

import { initialLabelFormValues } from '../formik/label-form.config';

import { getNodes } from '../utils/get-nodes';
import useQueryParam from '../hooks/use-query-param';
import { useSavedLabels } from '../hooks/use-saved-labels';
import { TCommonTemplateData } from '../models/common-template-data.model';

import MainLayout from '../layouts/main-layout';
import Title from '../components/atoms/title';
import LabelGenerator from '../components/organisms/label-generator';
import SavedLabels from '../components/organisms/saved-labels';
import Button from '../components/atoms/button';

const queryClient = new QueryClient();

interface ILabelGeneratorPage {
    readonly data: {
        allStrapiLabelStyle: IQueryAllResult<ILabelStyleCreator>;
        allStrapiWishes: IQueryAllResult<IWishes>;
    } & TCommonTemplateData;
}

const LabelGeneratorPage: React.FC<ILabelGeneratorPage> = ({ data }) => {
    const { t } = useI18next();
    const queryEdit = useQueryParam('edit');
    const queryStyle = useQueryParam('styl');
    const { savedLabelsCount, savedLabels, addNewSavedLabel, removeSavedLabel, saveSavedLabels } =
        useSavedLabels();
    const labelStyles = getNodes(data.allStrapiLabelStyle).sort((a, b) => a.rowOrder - b.rowOrder);
    const [openForm, setOpenForm] = useState(!savedLabels.length);
    const wishes = getNodes(data.allStrapiWishes);
    const [initValueRefresh, setInitValueRefresh] = useState(false);
    const occasion = labelStyles[0].occasion;

    useEffect(() => {
        if (savedLabelsCount < 1) setOpenForm(true);
    }, [savedLabelsCount]);

    const foundLabel = labelStyles.find((style) => {
        return (
            style.type.toLocaleLowerCase().trim() ===
            queryStyle?.values[0]?.toLocaleLowerCase().trim()
        );
    });

    const directories = [
        {
            name: 'wedding',
            displayName: `${t('label.occasion.wedding')}`,
            link: '/soplica-weselna/generator/?styl=Klasyczny',
            summary: '/soplica-weselna/podsumowanie',
        },
        {
            name: 'birthday',
            displayName: `${t('label.occasion.birthday')}`,
            link: '/soplica-urodzinowa/generator/?styl=Komiksowy',
            summary: '/soplica-urodzinowa/podsumowanie',
        },
        {
            name: 'anniversary',
            displayName: `${t('label.occasion.anniversary')}`,
            link: '/soplica-rocznicowa/generator/?styl=Glamour',
            summary: '/soplica-rocznicowa/podsumowanie',
        },
    ];

    const summaryLink = directories.filter((directory) => directory.name === occasion)[0].summary;

    const firstDate = savedLabels[0]?.date;

    const finalInitialLabelFormValues = useMemo(() => {
        if (queryEdit.values.length && queryEdit.values[0]) {
            const index = queryEdit.values[0];
            queryEdit.removeAll();
            const saveData = savedLabels[index];
            setOpenForm(true);
            setTimeout(() => {
                scrollToForm();
            }, 100);
            return saveData;
        }

        if (foundLabel) {
            setOpenForm(true);
            let extraValues: { [key: string]: string } = {
                style: foundLabel.type,
                secondLine: foundLabel.occasion !== 'birthday' ? '& ' : '',
            };
            if (savedLabelsCount) {
                extraValues = {
                    ...extraValues,
                    date: savedLabels[0].date,
                };
            }
            return {
                ...initialLabelFormValues,
                ...extraValues,
            };
        }

        if (savedLabelsCount) {
            return {
                ...initialLabelFormValues,
                style: savedLabels[0].style,
                date: savedLabels[0].date,
            };
        }

        if (!foundLabel && !savedLabelsCount) {
            return {
                ...initialLabelFormValues,
                style: labelStyles[0].type,
                capacity: labelStyles[0].capacities[0].capacity,
                occasion: labelStyles[0].occasion,
            };
        }

        return initialLabelFormValues;
    }, [savedLabels.length, firstDate, foundLabel, initValueRefresh]);

    const [formValues, setFormValues] = React.useState(finalInitialLabelFormValues);

    useEffect(() => {
        setFormValues({ ...finalInitialLabelFormValues, occasion: occasion });
    }, [finalInitialLabelFormValues]);

    const formRef = React.useRef<HTMLDivElement>(null);

    const editSavedLabel = (index: number) => {
        const savedLabel = savedLabels[index];
        setFormValues(savedLabel);
        setOpenForm(true);
        setTimeout(() => {
            scrollToForm();
        }, 100);
    };

    const scrollToForm = () => {
        const formContainer = formRef.current;
        const yOffset = -100;
        if (formContainer !== null) {
            const y = formContainer.offsetTop + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const handleCancelForm = () => {
        setOpenForm(false);
        setInitValueRefresh((prevState) => !prevState);
    };

    return (
        <MainLayout
            className={layout}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            page={data.page}
        >
            <div className={container}>
                <Title className={title} Tag="h1">
                    {t('label.creator.title')}
                </Title>
                <p className={description}>{t('label.creator.description')}</p>
                {savedLabels.length > 0 && (
                    <SavedLabels
                        labels={savedLabels}
                        onRemoveSavedLabel={removeSavedLabel}
                        onEditSavedLabel={editSavedLabel}
                        labelStyles={labelStyles}
                    />
                )}
                {openForm && (
                    <QueryClientProvider client={queryClient}>
                        <div ref={formRef} className={savedLabels.length > 0 ? formWithBorder : ''}>
                            <LabelGenerator
                                savedLabels={savedLabels}
                                saveSavedLabels={saveSavedLabels}
                                initialValues={formValues}
                                onAddNewSavedLabel={(values, image) => {
                                    addNewSavedLabel(values, image);
                                    queryStyle.removeAll();
                                }}
                                onHandleAddSavedLabel={() => {
                                    setOpenForm(false);
                                    setInitValueRefresh((prevState) => !prevState);
                                }}
                                onHandleCancelLabelForm={handleCancelForm}
                                labelStyles={labelStyles}
                                wishes={wishes}
                                occasion={occasion}
                                directories={directories}
                            />
                        </div>
                    </QueryClientProvider>
                )}
                {(!openForm || (openForm && savedLabels.length > 0)) && (
                    <div className={buttonsWrapper}>
                        {!openForm && (
                            <Button onClick={() => setOpenForm(true)} variant={'text'}>
                                {t('label.creator.add.more')}
                            </Button>
                        )}
                        <Button as={'link'} to={summaryLink}>
                            {t('label.creator.next.step')}
                        </Button>
                    </div>
                )}
            </div>
            {/* Loading fonts before canvas needs to use them, resolve problem of not showing correct font on first try */}
            {occasion === 'wedding' && (
                <>
                    <div className={`${preloadFonts} ${caveatRegular}`}>.</div>
                    <div className={`${preloadFonts} ${libreBodoniItalic}`}>.</div>
                    <div className={`${preloadFonts} ${marckScript}`}>.</div>
                    <div className={`${preloadFonts} ${msMadiRegular}`}>.</div>
                    <div className={`${preloadFonts} ${whisper}`}>.</div>
                </>
            )}
            {occasion === 'birthday' && (
                <>
                    <div className={`${preloadFonts} ${abrilFatFace}`}>.</div>
                    <div className={`${preloadFonts} ${alexBrush}`}>.</div>
                    <div className={`${preloadFonts} ${bangers}`}>.</div>
                    <div className={`${preloadFonts} ${libreBodoni}`}>.</div>
                    <div className={`${preloadFonts} ${whisper}`}>.</div>
                    <div className={`${preloadFonts} ${beachworks}`}>.</div>
                    <div className={`${preloadFonts} ${avenir}`}>.</div>
                    <div className={`${preloadFonts} ${avenirCondensed}`}>.</div>
                </>
            )}

            {occasion === 'anniversary' && (
                <>
                    <div className={`${preloadFonts} ${alexBrush}`}>.</div>
                    <div className={`${preloadFonts} ${greatVibes}`}>.</div>
                    <div className={`${preloadFonts} ${lobster}`}>.</div>
                </>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!, $occasion: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allStrapiWishes(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    title
                }
            }
        }

        allStrapiLabelStyle(filter: { occasion: { eq: $occasion }, locale: { eq: $language } }) {
            edges {
                node {
                    type
                    occasion
                    ...labelStyleBottleImageField
                    ...labelStyleSampleImageField
                    ...labelStyleCleanImageField
                    ...labelSmallStyleBottleImageField
                    ...labelSmallStyleSampleImageField
                    ...labelSmallStyleCleanImageField
                    ...labelLemonStyleBottleImageField
                    ...labelLemonStyleSampleImageField
                    ...labelLemonStyleCleanImageField
                    ...labelImageDigits
                    ...labelStyleTextStyles
                    ...labelSmallStyleTextStyles
                    ...labelLemonStyleTextStyles
                    capacities {
                        capacity
                        id
                    }
                    smallCapacities {
                        capacity
                        id
                    }
                    labelStyleId
                    fontFamily
                    rowOrder
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default LabelGeneratorPage;
