import { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

export default function useQueryParam(paramName: string) {
    const { search } = useLocation();
    const urlParams = useRef(new URLSearchParams(search));
    const [values, setValues] = useState(urlParams.current.getAll(paramName));
    const liveValues = urlParams.current.getAll(paramName);

    const removeAll = () => {
        urlParams.current.delete(paramName);
        window.history.replaceState(null, '', window.location.pathname);
        setValues(urlParams.current.getAll(paramName));
    };

    const setValue = useCallback(
        (slug: string, noScroll: boolean) => {
            let urlParams = `${paramName}=${slug}`;
            if (noScroll) urlParams = urlParams + '&no-scroll=true';

            window.history.pushState({}, '', `?${urlParams}`);
        },
        [paramName]
    );

    const appendValue = useCallback(
        (slug: string) => {
            const currentValues = getValues(urlParams.current, paramName);
            const indexOfCategory = currentValues.indexOf(slug);

            if (indexOfCategory > -1) {
                currentValues.splice(indexOfCategory, 1);
            } else {
                currentValues.push(slug);
            }

            if (currentValues.length === 0) {
                urlParams.current.delete(paramName);
            } else {
                urlParams.current.set(paramName, currentValues.join(','));
            }

            navigate(`?${urlParams.current.toString()}`);
        },
        [paramName]
    );

    const checkValue = useCallback(
        (slug: string) => {
            return getValues(urlParams.current, paramName).includes(slug);
        },
        [paramName]
    );

    useEffect(() => {
        const nextSearchParams = new URLSearchParams(search);
        const areParamsEqual =
            nextSearchParams.getAll(paramName).toString() ===
            urlParams.current.getAll(paramName).toString();

        if (areParamsEqual) {
            return;
        }

        urlParams.current = new URLSearchParams(search);
    }, [search, paramName]);

    return {
        removeAll,
        appendValue,
        checkValue,
        setValue,
        values,
        liveValues,
    };
}

const getValues = (urlParams: URLSearchParams, paramName: string) => {
    return urlParams.getAll(paramName).join(',').split(',').filter(Boolean);
};
