// extracted by mini-css-extract-plugin
export var abrilFatFace = "label-generator-page-module--abrilFatFace--df22d";
export var alexBrush = "label-generator-page-module--alexBrush--012ef";
export var avenir = "label-generator-page-module--avenir--bac07";
export var avenirCondensed = "label-generator-page-module--avenirCondensed--cb747";
export var backgroundAnimation = "label-generator-page-module--backgroundAnimation--bffd4";
export var bangers = "label-generator-page-module--bangers--187b7";
export var beachworks = "label-generator-page-module--beachworks--cba4b";
export var buttonsWrapper = "label-generator-page-module--buttons-wrapper--7e745";
export var caveatRegular = "label-generator-page-module--caveatRegular--8fd5c";
export var container = "label-generator-page-module--container--97b55";
export var description = "label-generator-page-module--description--fbebb";
export var errorBlink = "label-generator-page-module--error-blink--ed469";
export var formWithBorder = "label-generator-page-module--formWithBorder--52ad2";
export var greatVibes = "label-generator-page-module--greatVibes--0032d";
export var layout = "label-generator-page-module--layout--745e0";
export var libreBodoni = "label-generator-page-module--libreBodoni--f71f5";
export var libreBodoniItalic = "label-generator-page-module--libreBodoniItalic--58f0b";
export var lobster = "label-generator-page-module--lobster--8e2a7";
export var marckScript = "label-generator-page-module--marckScript--55dfb";
export var msMadiRegular = "label-generator-page-module--msMadiRegular--e98aa";
export var preloadFonts = "label-generator-page-module--preload-fonts--255d5";
export var title = "label-generator-page-module--title--13bed";
export var whisper = "label-generator-page-module--whisper--b6c0f";