import { ICreateLabelValues } from '../models/create-form.model';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const initialLabelFormValues: ICreateLabelValues = {
    id: undefined,
    occasion: undefined,
    image: '',
    style: '',
    date: '',
    capacity: '500 ml',
    quantity: '',
    firstLine: '',
    secondLine: '',
    city: '',
    wishes: '',
    years: undefined
};

export function getLabelFormSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape({
        occasion: Yup.string(),
        style: Yup.string().required(t('form.required.error')),
        date: Yup.string().required(t('form.required.error')),
        capacity: Yup.string().required(t('form.required.error')),
        quantity: Yup.number().when('capacity', {
            is: (capacity: string) => capacity === '500 ml lemon',
            then: Yup.number()
                .min(15, t('label.creator.quantity.lemon.hint'))
                .required(t('form.required.error')),
            otherwise: Yup.number()
                .min(30, t('label.creator.quantity.hint'))
                .required(t('form.required.error')),
        }),
        firstLine: Yup.string()
            .max(16, t('label.creator.input.hint.first'))
            .required(t('form.required.error')),
        secondLine: Yup.string().when('occasion', {
            is: (occasion: string) => occasion === 'wedding' || occasion === 'anniversary',
            then:  Yup.string()
                .min(3, t('form.required.error'))
                .max(14, t('label.creator.input.hint.second'))
                .required(t('form.required.error')),
            otherwise: Yup.string()
        }),
        city: Yup.string().when('occasion', {
            is: (occasion: string) => occasion === 'wedding',
            then: Yup.string().max(16, t('label.creator.city.hint')),
            otherwise: Yup.string()
        }),
        wishes: Yup.string().when('capacity', {
            is: (capacity: string) => capacity === '100 ml',
            then: Yup.string().required(t('form.required.error')),
            otherwise: Yup.string()
        }),
        years: Yup.number().when('occasion', {
            is: (occasion: string) => occasion === 'birthday' || occasion === 'anniversary',
            then: Yup.number()
                .min(1, t('label.creator.years.min.hint'))
                .max(150, t('label.creator.years.max.hint'))
                .required(t('form.required.error')),
            otherwise: Yup.number()
        })
    });
}
